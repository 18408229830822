import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">


<path d="M2945 3579 c-353 -154 -695 -301 -969 -419 -98 -42 -182 -84 -188
-94 -15 -28 -1 -65 28 -72 25 -6 76 12 318 117 49 22 96 39 106 39 41 0 67
-73 36 -100 -8 -6 -60 -31 -115 -54 -55 -23 -107 -48 -115 -55 -20 -17 -20
-55 0 -75 19 -20 35 -20 81 -1 43 18 85 19 101 3 13 -13 16 -57 4 -74 -8 -14
-81 -48 -297 -138 -231 -97 -253 -107 -272 -128 -16 -18 -16 -22 -2 -46 20
-36 48 -38 116 -8 218 95 370 156 389 156 45 0 70 -56 42 -91 -7 -9 -42 -28
-78 -43 -91 -40 -110 -53 -110 -80 0 -31 33 -60 62 -53 13 3 73 26 133 52 61
26 144 62 185 79 41 18 85 37 97 44 21 12 176 222 169 229 -2 2 -28 -7 -57
-20 -74 -34 -270 -117 -275 -117 -2 0 -4 84 -4 188 1 292 -9 282 386 450 378
162 425 185 475 234 44 44 83 118 94 181 6 28 4 37 -6 36 -8 0 -158 -63 -334
-140z"/>
<path d="M2860 3189 c-165 -72 -182 -84 -235 -163 -20 -28 -60 -86 -90 -128
-31 -43 -53 -78 -51 -78 3 0 77 32 166 70 89 39 163 70 165 70 3 0 5 -71 5
-159 0 -162 -4 -185 -44 -243 -27 -41 -67 -63 -356 -190 -294 -129 -339 -158
-385 -253 -32 -65 -46 -141 -24 -133 13 5 126 54 702 305 321 140 308 115 305
608 l-3 362 -155 -68z"/>
<path d="M1826 2852 c-99 -41 -115 -57 -100 -99 13 -39 42 -40 122 -7 101 42
114 52 110 87 -6 57 -32 60 -132 19z"/>
<path d="M1899 2397 c-65 -29 -79 -74 -33 -104 25 -16 28 -16 73 8 53 28 63
57 32 88 -23 23 -34 25 -72 8z"/>
<path d="M1635 2285 c-114 -50 -136 -71 -111 -110 21 -32 47 -32 132 4 154 64
171 79 140 126 -22 34 -44 31 -161 -20z"/>
<path d="M972 1790 c-12 -5 -27 -21 -34 -34 -14 -32 1 -52 59 -77 24 -10 43
-23 43 -29 0 -20 -31 -22 -65 -4 -19 10 -37 15 -41 12 -12 -12 7 -45 32 -56
34 -16 59 -15 94 3 23 12 30 22 30 45 0 32 -24 53 -72 65 -18 5 -28 14 -28 26
0 23 35 26 44 4 6 -17 42 -20 51 -5 9 15 -41 60 -67 59 -13 0 -33 -4 -46 -9z"/>
<path d="M1134 1783 c3 -10 10 -40 16 -68 27 -123 28 -125 65 -125 l34 0 12
76 c14 86 24 82 35 -14 l6 -63 36 3 36 3 27 103 27 102 -27 0 c-27 0 -28 -2
-41 -82 -14 -90 -23 -86 -35 20 l-7 62 -38 0 -38 0 -11 -63 c-6 -35 -11 -72
-11 -82 0 -37 -11 -5 -23 66 -12 70 -14 74 -41 77 -23 3 -27 0 -22 -15z"/>
<path d="M1470 1695 l0 -105 25 0 25 0 0 105 0 105 -25 0 -25 0 0 -105z"/>
<path d="M1580 1695 c0 -105 0 -105 24 -105 22 0 25 5 28 43 l3 42 48 3 c39 3
47 6 47 23 0 16 -7 19 -51 19 -43 0 -50 3 -47 18 2 13 15 18 56 20 44 3 52 6
52 23 0 17 -7 19 -80 19 l-80 0 0 -105z"/>
<path d="M1770 1781 c0 -15 8 -21 33 -23 l32 -3 3 -82 c3 -81 3 -83 27 -83 25
0 25 1 25 85 l0 85 30 0 c23 0 30 4 30 20 0 19 -7 20 -90 20 -82 0 -90 -2 -90
-19z"/>
<path d="M2070 1694 l0 -106 65 4 c74 5 109 29 119 82 15 78 -36 126 -133 126
l-51 0 0 -106z m114 50 c9 -8 16 -31 16 -49 0 -40 -22 -65 -56 -65 -23 0 -24
3 -24 65 0 62 1 65 24 65 14 0 32 -7 40 -16z"/>
<path d="M2328 1695 l-37 -105 28 0 c24 0 30 5 35 30 5 26 10 30 40 30 29 0
36 -5 45 -30 8 -23 16 -30 37 -30 17 0 24 4 22 13 -3 6 -20 54 -38 105 -32 90
-34 92 -64 92 -31 0 -32 -2 -68 -105z m82 13 c0 -10 -7 -18 -15 -18 -17 0 -18
4 -8 38 4 18 8 20 14 10 5 -7 9 -21 9 -30z"/>
<path d="M2530 1780 c0 -16 7 -20 30 -20 l30 0 0 -85 c0 -84 0 -85 25 -85 24
0 24 2 27 83 l3 82 33 3 c24 2 32 8 32 23 0 17 -8 19 -90 19 -83 0 -90 -1 -90
-20z"/>
<path d="M2768 1695 l-37 -105 29 0 c23 0 30 5 35 30 6 26 11 30 41 30 30 0
35 -4 41 -30 5 -25 10 -30 35 -30 20 0 29 4 26 13 -3 6 -20 54 -38 105 -32 90
-34 92 -64 92 -31 0 -32 -2 -68 -105z m82 13 c0 -10 -7 -18 -15 -18 -17 0 -18
4 -8 38 4 18 8 20 14 10 5 -7 9 -21 9 -30z"/>
<path d="M3060 1695 l0 -105 25 0 c23 0 25 4 25 40 l0 40 46 0 46 0 -4 -37
c-3 -35 -1 -38 25 -41 l27 -3 0 105 0 106 -25 0 c-23 0 -25 -4 -25 -45 l0 -45
-45 0 -45 0 0 45 c0 41 -2 45 -25 45 l-25 0 0 -105z"/>
<path d="M3310 1716 c0 -80 1 -86 28 -106 21 -17 36 -21 65 -17 65 9 77 28 77
124 0 82 0 83 -25 83 -24 0 -24 -2 -27 -82 l-3 -83 -30 0 -30 0 -3 83 c-3 80
-3 82 -27 82 -25 0 -25 -1 -25 -84z"/>
<path d="M3540 1695 l0 -105 56 0 c54 0 92 12 106 34 13 19 9 53 -7 66 -11 9
-13 16 -5 24 17 17 11 54 -12 70 -14 10 -44 16 -80 16 l-58 0 0 -105z m108 43
c3 -14 -3 -18 -27 -18 -29 0 -41 17 -24 34 13 13 48 1 51 -16z m7 -83 c0 -13
-8 -21 -24 -23 -28 -4 -46 11 -37 33 9 25 61 16 61 -10z"/>
<path d="M1133 1455 c0 -27 2 -40 4 -27 3 15 11 22 28 22 17 0 25 -7 28 -22 2
-13 4 0 4 27 0 28 -2 40 -4 28 -3 -16 -11 -23 -28 -23 -17 0 -25 7 -28 23 -2
12 -4 0 -4 -28z"/>
<path d="M1450 1486 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M1517 1493 c-3 -5 -1 -26 3 -48 l8 -40 1 48 c1 45 -2 56 -12 40z"/>
<path d="M1620 1455 c0 -44 0 -45 33 -44 30 2 30 2 5 6 -34 5 -37 30 -5 36
l22 4 -22 2 c-33 2 -29 29 5 34 25 4 25 4 -5 6 -33 1 -33 0 -33 -44z"/>
<path d="M1862 1488 c-26 -26 -7 -79 28 -77 9 0 6 4 -7 9 -16 6 -23 18 -23 35
0 17 7 29 23 35 13 5 16 9 7 9 -9 1 -21 -4 -28 -11z"/>
<path d="M2011 1453 c1 -27 4 -38 6 -26 2 13 13 24 26 26 l22 4 -22 2 c-13 0
-23 7 -23 15 0 8 10 17 23 19 19 4 18 4 -5 6 -27 1 -28 -1 -27 -46z"/>
<path d="M2253 1493 c-8 -3 -12 -20 -11 -41 l1 -37 4 33 c5 38 25 50 51 31 15
-13 16 -12 3 4 -14 17 -25 20 -48 10z"/>
<path d="M2554 1486 c-3 -8 -4 -27 -2 -43 2 -23 8 -28 33 -29 29 -2 29 -2 3 3
-34 5 -37 30 -5 36 l22 4 -22 2 c-33 2 -29 29 5 34 l27 4 -28 2 c-16 0 -30 -5
-33 -13z"/>
<path d="M2711 1482 c-15 -29 -14 -42 5 -42 9 0 23 -8 30 -17 12 -16 12 -16 7
5 -3 13 -13 22 -24 22 -20 0 -26 15 -9 25 6 3 10 1 10 -6 0 -7 3 -10 6 -6 3 3
1 13 -4 23 -9 16 -10 16 -21 -4z"/>
<path d="M2841 1453 c1 -27 4 -38 6 -26 2 12 12 24 21 26 15 4 15 5 0 6 -21 1
-24 31 -3 31 7 0 19 -8 24 -17 6 -10 11 -13 11 -7 0 16 -22 34 -42 34 -15 0
-18 -8 -17 -47z"/>
<path d="M3070 1455 c0 -40 2 -45 23 -45 29 0 47 18 47 45 0 27 -18 45 -47 45
-21 0 -23 -5 -23 -45z m55 0 c0 -23 -5 -31 -22 -33 -20 -3 -23 1 -23 33 0 32
3 36 23 33 17 -2 22 -10 22 -33z"/>
<path d="M3246 1484 c-4 -9 -4 -19 -1 -22 3 -3 5 0 5 7 0 7 5 9 10 6 17 -10
11 -25 -10 -25 -11 0 -20 -4 -20 -9 0 -4 11 -6 25 -3 19 3 25 0 25 -14 0 -9 3
-15 6 -12 7 8 -17 88 -27 88 -4 0 -10 -7 -13 -16z"/>
<path d="M3388 1493 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M3537 1487 c-3 -8 1 -14 8 -14 7 0 11 6 8 14 -3 7 -6 13 -8 13 -2 0
-5 -6 -8 -13z"/>
<path d="M1287 1483 c-9 -9 -9 -51 1 -56 4 -3 7 10 7 29 0 19 0 34 -1 34 0 0
-4 -3 -7 -7z"/>
<path d="M1345 1455 c0 -18 3 -31 8 -28 4 2 7 15 7 28 0 13 -3 26 -7 28 -5 3
-8 -10 -8 -28z"/>
<path d="M1911 1485 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M3402 1450 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M2418 1453 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M3540 1449 c-23 -6 -23 -7 -3 -8 12 -1 27 -9 32 -18 12 -20 13 -7 3
18 -5 11 -14 13 -32 8z"/>
<path d="M1480 1420 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2268 1413 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
</g>
</svg>


    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
